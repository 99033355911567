import React from 'react'
import styled from '@emotion/styled'
import graphic from '../images/header.svg'
import SearchIcon from '@mui/icons-material/Search'
import { Helmet } from 'react-helmet'
import techlabsLogo from '../images/logo/logo_white.svg'

import { Button, IconButton } from 'gatsby-theme-material-ui'
import Drawer from '@mui/material/Drawer'
import useWindowWidth from '../utils/useWindowWidth'
import MenuIcon from '@mui/icons-material/Menu'

const HeaderContainer = styled.div`
  height: 10rem;
`

// Allows those using accesibility features to skip directly to the #main content of the page
const SkipLink = styled.a`
  left: 50%;
  position: absolute;
  transform: translateY(-100%);
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  color: #004dbe;

  &:focus {
    transform: translateY(0%);
  }
`

const BackgroundImage = styled.img`
  width: 100vw;
  height: 14rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`

const Logo = styled.img`
  width: 6.5rem;
  margin-left: 1.5rem;
  margin-top: 1.4rem;
`

const MobileMenuButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

const MobileMenu = styled.div`
  width: 16rem;
  margin-top: 2rem;
`

const DesktopMenuContainer = styled.div`
  position: absolute;
  top: 22px;
  right: 0;
  width: fit-content;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-around;
  align-items: start;
  margin: 0 2rem;
  gap: 2rem;
  row-gap: 0.2rem;
`

const MenuItem = styled(Button)`
  justify-content: start;
  size: large;
  font-size: 1.06rem;
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  text-decoration-thickness: 2px;
`

const Menu = ({ color, direction, items, onItemSelect }) => {
  const currentPath = typeof window !== 'undefined' ? window.location.pathname : ''

  return (
    <MenuContainer direction={direction}>
      <IconButton to="/search" color={color} aria-label="search" onClick={onItemSelect}>
        <SearchIcon color={color} />
      </IconButton>
      {items.map((item) => (
        <MenuItem
          key={item.label}
          color={color}
          to={item.to}
          onClick={onItemSelect}
          active={currentPath.startsWith(item.to)}
        >
          {item.label}
        </MenuItem>
      ))}
    </MenuContainer>
  )
}

const Header = () => {
  const windowWidth = useWindowWidth()
  const [drawerState, setDrawerState] = React.useState(false)

  // Simply add entires here for more nav links
  const menuItems = [
    { label: 'People', to: '/people' },
    { label: 'Posts', to: '/posts' },
    { label: 'Labs', to: '/labs' },
    { label: 'Projects', to: '/projects' },
    { label: 'Publications', to: '/publications' },
    { label: 'Equipment', to: '/equipment' },
  ]

  return (
    <HeaderContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <SkipLink href="#main">Skip to content</SkipLink>
      <BackgroundImage src={graphic} alt="" />

      <a href="/">
        <Logo src={techlabsLogo} alt="" />
      </a>

      {windowWidth < 1000 ? (
        <div>
          <MobileMenuButton color={'secondary'} onClick={() => setDrawerState(!drawerState)}>
            <MenuIcon fontSize="large" />
          </MobileMenuButton>
          <Drawer anchor="right" open={drawerState} onClose={() => setDrawerState(!drawerState)}>
            <MobileMenu>
              <Menu color="primary" direction="column" items={menuItems} onItemSelect={() => setDrawerState(false)} />
            </MobileMenu>
          </Drawer>
        </div>
      ) : (
        <DesktopMenuContainer>
          <Menu color="secondary" direction="row" items={menuItems} onItemSelect={() => setDrawerState(false)} />
        </DesktopMenuContainer>
      )}
    </HeaderContainer>
  )
}

export default Header
