
import React from "react"
import Footer from "./footer"
import Header from "./header"
import { css } from '@emotion/react'

const MainContext = React.createContext()

// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere as well as the header and footer.
const Layout = ({ children, pageContext: { locale } }) => (
  <MainContext.Provider value={{ locale }}>
    {/* arcada-styling */}
    <div css={css`position: relative; min-height: 100vh; margin: 0; `}> {/* font-family: futura-pt; */}
      <Header />

      <main id="main" css={css`
        position: static; 
        display: block; 

        &::after{
          content: '';
          display: block;
          height: 22rem;
        }
        `}
      >
      {children}
      </main>
            
      <Footer discrete={false} css={css`position: static; width: 100%; height: 22rem;`} />
    </div>
  </MainContext.Provider>
)

export { Layout, MainContext }