import React from 'react'
import styled from '@emotion/styled'
import FooterGraphic from '../images/gradiant-footer-small-screen.svg'
import GitHubIcon from '@mui/icons-material/GitHub'
import { IconButton, Link } from 'gatsby-theme-material-ui'
import SearchIcon from '@mui/icons-material/Search'
import Tooltip from '@mui/material/Tooltip'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import FingerprintIcon from '@mui/icons-material/Fingerprint'

const FooterContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`

const FooterImage = styled.img`
  width: 100vw;
  height: 16rem;
  position: absolute;
  bottom: 0;
`

const FooterContent = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 1rem;

  * {
    color: white;
    margin: 0;
  }
`

const FooterLink = styled.a`
  &:visited,
  &:hover,
  &:active,
  &:link {
    text-decoration: none;
  }
`

const IconLink = ({ title, to, IconComponent, ariaLabel }) => {
  const isExternal = to.startsWith('http')

  return (
    <Tooltip title={title} placement="top">
      {isExternal ? (
        <IconButton component="a" href={to} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel}>
          <IconComponent color="secondary" />
        </IconButton>
      ) : (
        <IconButton component={Link} to={to} aria-label={ariaLabel}>
          <IconComponent color="secondary" />
        </IconButton>
      )}
    </Tooltip>
  )
}

const Footer = () => (
  <FooterContainer>
    <FooterImage alt="" src={FooterGraphic} />
    <FooterContent>
      <p>Copyright ©{new Date().getFullYear()} TechLabs</p>
      <address style={{ marginBottom: '1rem' }}>
        <FooterLink href="https://goo.gl/maps/cySyoWeFynrixuCZA">
          Jan-Magnus Janssonin aukio 1<br />
          00580 Helsinki
        </FooterLink>
      </address>

      <IconLink
        title="Arcada UAS Github"
        to="https://github.com/arcada-uas"
        IconComponent={GitHubIcon}
        ariaLabel="Arcada UAS Github"
      />
      <IconLink
        title="Privacy policy"
        to="/privacy-policy"
        IconComponent={FingerprintIcon}
        ariaLabel="Privacy Policy"
      />
      <IconLink
        title="Accessibility"
        to="/accessibility"
        IconComponent={AccessibilityNewIcon}
        ariaLabel="Accessibility"
      />
      <IconLink title="Search" to="/search" IconComponent={SearchIcon} ariaLabel="search" />
    </FooterContent>
  </FooterContainer>
)

export default Footer
